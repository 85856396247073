#cookies__wrapper .modal-dialog, #cookies__preferences .modal-dialog {
    max-width: 800px;
    margin: auto;
}
#cookies__wrapper .modal-content, #cookies__preferences .modal-content {
    position: relative;
    border-radius: 33px;
    border: none;
    text-align: center;
    padding: 25px 25px 15px;
}

#cookies__wrapper, #cookies__wrapper .modal-dialog, #cookies__wrapper .modal-content  {
    z-index: 99999;
}
#cookies__wrapper .modal-content .modal-body {
    z-index: 99999;
}
#cookies__preferences .modal-content .modal-body {
    z-index: 9998;
    padding: 0 !important;
}
#cookies__wrapper .modal-content:after, #cookies__preferences .modal-content:after {
    content: "";
    position: absolute;
    left: -20px;
    width: 100%;
    height: calc(100% - 50px);
    bottom: -20px;
    border-radius: 33px;
    background: rgb(255,255,255);
    background: linear-gradient(90deg, rgba(255,255,255,0.227328431372549) 0%, rgba(255,255,255,0) 100%);
    z-index: -1;
}
#cookies__wrapper h5.title, #cookies__preferences h5.title {
    font-family: Quicksand-Bold;
    font-size: 25px;
    color: #E9600F;
    margin-bottom: 30px;
}
.alw_act {
    font-size: 13px;
}
#cookies__wrapper .list_actions {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 30px;
}
#cookies__wrapper .list_actions a, #cookies__preferences .list_actionsB a {
    font-family: Quicksand-Medium;
    text-decoration: none;
    font-size: 13px;
    padding: 10px;
    border-radius: 100px;
    background-color:  #E9600F;
    color: #fff;
    border: none;
    width: 180px;
}
#cookies__wrapper .list_actions a.gray_bg, #cookies__preferences .list_actionsB a.gray_bg{    
    background-color:  #555555;
    cursor: pointer;
}
#cookies__wrapper .policies__cookies_link {    
   margin-top: 10px;
}
#cookies__wrapper .policies__cookies_link a {
    font-size: 13px;
    color: #0B66FE;
    font-family: Quicksand-Medium;
}
#cookies__wrapper .policies__cookies_link a:hover {
    border: none;
}
 
.text-align-justify {
    text-align: justify !important;
}

.banner-categories{
    display: grid;
    align-items: center;
    justify-content: center;
}

#cookies__wrapper .modal-body, #cookies__preferences .modal-body{
    height: 330px;
    overflow-y: auto;
}
.banner-category {
    padding-bottom: 10px;
}
.p-l-modal{
    padding-left: 15px;
    padding-right: 15px;
}

.mt-bot {
    margin-top: 51px;
}
#cookies__preferences p, #cookies__wrapper p {
    font-size: 14px !important;
}
.text-adapte {
    font-size: 12px !important;
}
.m-t-neg {
    margin-top: -13px;
}

.get-pad {
    margin-bottom: 14px !important;
}

.color-org {
    color: #E9600F !important;
}
.second-title {
    font-size: 22px;
}
.size-ht {
    font-size: 29px !important;
}
.size-h2 {
    font-size: 24px !important;
}
.modal-content.heigh-cook .modal-header{
    border-bottom: none;
    justify-content: center !important;
}
.modal-content.heigh-cook .modal-body{
    justify-content: center;
    display: flex;
    align-items: center;
}
.modal-content.heigh-cook .modal-body p{
    margin-bottom: 0;
}
.modal-content.heigh-cook .modal-footer{
    border-top: none;
    justify-content: center !important;
}
.width-style {
    width: 100%;
}
#cookies__preferences .list_actionsB {
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.banner-category .banner-description{
    display: none;
}
.banner-category.banner-category-active .banner-description {
    display: block;
}
.banner-categories br{
    display: none;
}
.banner-category summary{
    position: relative;
}
.banner-category-active .arrow{
    transform: rotate(90deg);
}
.banner-category-header {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}
.banner-category summary .arrow { 
    width: 0; 
    height: 0; 
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 8px solid #000;
    top: 4px;
    position: absolute;
    left: -16px;
    transition: .2s all;
}
details > summary {
    list-style: none;
}
.cat {
    display: flex;
    justify-content: space-between;
}
.cat-choi {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 71%;
}
.banner-manage-consent-container .banner-categories {
    display: grid;
    align-items: center;
    justify-content: space-between;
}
.banner-manage-consent-container .banner-categories p{
    margin-bottom: 0;
}
span.banner-always-active {
    padding-left: 10px;
}
@media only screen and (min-width: 544px) and (max-width: 772px) {
    .define_text {
        /*max-width: 220px;*/
        display: block;
        /* overflow-y: scroll; */
        max-height: 161px;
    }
    .modal-content.heigh-cook .modal-body {
        align-items: baseline;
    }
    .modal-content.heigh-cook {
        height: 500px !important;
    }
    .modal-content.heigh-cook.pref {
        height: 600px !important;
    }
    .modal-content.heigh-cook .modal-body {
        align-items: baseline !important;
    }
}
@media only screen and (max-width: 543px) {
    .define_text {
        /*max-width: 220px;*/
        display: block;
        /* overflow-y: scroll; */
        max-height: 161px;
    }
    .modal-content.heigh-cook .modal-body {
        align-items: baseline;
    }
    .modal-content.heigh-cook {
        height: 600px !important;
    }
    .modal-content.heigh-cook.pref {
        height: 600px !important;
    }
    .modal-content.heigh-cook .modal-body {
        align-items: baseline !important;
    }
}
@media only screen and (max-width: 488px) {
    #cookies__wrapper h5.title, #cookies__preferences h5.title{
        font-size: 20px;
    }
    
}
details.banner-category.banner-functional.float-left{
    text-align: left;
}
.modal-content.heigh-cook {
    height: 400px;
}
.modal-content.heigh-cook.pref {
    height: 500px;
}

/*@media only screen and (max-width:800px) {
    .modal-content.heigh-cook {
        height: 405px;
    }
}
@media only screen and (max-width: 700px) {
    .modal-content.heigh-cook {
        height: 490px;
    }
}*/

 
@media only screen and (max-width: 991px) {
    .prin-title {
        font-size: 20px !important;
    }
}
@media only screen and (max-width: 991px) {
    #cookies__wrapper .modal-dialog, #cookies__preferences .modal-dialog  {
        max-width: calc(100% - 120px);
    }
    #cookies__wrapper .list_actions {
        gap: 10px;
    }
}